<template>
    <div class="shop_main noMenu">
        <div class="shop_order_main">
            <userMenu type="7" />
            <div class="supplyBuyingAttentionList">
                <div class="list" v-for="(list, index) in listData" :key="index">
                    <div class="store">
                        <img src="../../../../assets/img/shop_order.png" class="storeIcon" />
                        <span class="storeName">{{list.companyName}}</span>
                        <el-button @click="toCancelAttention(list.companyId)">已关注</el-button>
                    </div>

                    <div class="shopBuyingIndexList">
                        <infoItem v-for="(item, i) in list.purchaseInfo.list" :key="index+ '' + i" :item="item"/>
                        <noDataView v-if="list.purchaseInfo.list.length === 0"/>
                    </div>

                    <div class="page">
                        <el-select size="mini" v-model="list.type" @change="() => getAttentionInfo(list.companyId, 1, list.type, index)">
                            <el-option label="全部" :value="null"></el-option>
                            <el-option label="求购单" :value="1"></el-option>
                            <el-option label="供应单" :value="2"></el-option>
                        </el-select>

                        <span class="text">{{list.purchaseInfo.pageNum}}/{{list.purchaseInfo.pages}}</span>

                        <el-button icon="el-icon-arrow-left" size="mini" :disabled="list.purchaseInfo.pageNum === 1" @click="getAttentionInfo(list.companyId, list.purchaseInfo.pageNum - 1, list.type, index)"></el-button>
                        <el-button icon="el-icon-arrow-right" size="mini" :disabled="list.purchaseInfo.pageNum === list.purchaseInfo.pages" @click="getAttentionInfo(list.companyId, list.purchaseInfo.pageNum + 1, list.type, index)"></el-button>
                    </div>
                </div>

                <noDataView v-if="listData.length === 0"/>

                <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange" />
            </div>
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";
  import {getAttention, getAttentionInfo, postCompanyAttention} from '../../../../api/shop/supplyBuying'
  const pagination = () => import('../../../../components/pagination/shop')
  const userMenu = () => import('../../../../components/layout/shop/userMenu')
  const noDataView = () => import('../../../../components/noDataView')
  const infoItem = () => import('../../../../components/layout/shop/supplyBuying/infoItem')
  export default {
    name: "shopSupplyBuyingAttention",
    data () {
      return {
        listQuery: {
          pageInfo: 1,
          pageInfoSize: 3,
          page: 1,
          pageSize: 5
        },
        pages: {
          total: 0,
          pages: 0
        },
        listData: [],
        isRequestLoading: false,
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
      })
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.$store.commit('changeScrollTop');
        this.listQuery.page = 1
      }
      if (this.listKeepAlive.isQueryList) {
        this.getList()
      }
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        getAttention(this.listQuery).then(res => {
          this.isRequestLoading = false
          this.isSearchLoading = false
          if (res.code === 0) {
            this.listData = res.data.list
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        }).catch(() => {
          this.isRequestLoading = false
          this.isSearchLoading = false
        })
      },
      pageChange (e) {
        this.listQuery.page = e.current
        this.listQuery.pageSize = e.size
        this.getList()
      },
      toSearchList () {
        this.listQuery.page = 1
        this.isSearchLoading = true
        this.getList()
      },
      getAttentionInfo (companyId, pageInfo, type, index) {
        getAttentionInfo({
          companyId: companyId,
          pageInfo: pageInfo,
          pageInfoSize: 3,
          type: type
        }).then(res => {
          if (res.code === 0) {
            this.listData[index].purchaseInfo = res.data
          }
        })
      },
      toCancelAttention (id) {
        this.$confirm('是否确定不在关注此企业信息？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          postCompanyAttention(id, 1).then(res => {
            if (res.code === 0) {
              this.getList()
            }
          })
        }).catch(() => {});
      },
    },
    components: {
      pagination, userMenu, noDataView, infoItem
    }
  }
</script>
